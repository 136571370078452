@import "../../../vendors/variables";
@import "../../../vendors/font.css";

.policybody{
    background-color: #f3f6fc;
    padding:5%;
  
  .table-content{
    li{
        margin-bottom: 1%;
        a{
        color: black;
        cursor: pointer;
        font-weight: 600;
    }
    }

  }
  h5{
    font-weight: 700;
  }
  h6{
    font-size: 14px;
    font-weight: 600;
  }
  .table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
    td,th{
        padding: 1%;
    }
  }


  .sections{
    border: 1px solid black;
    padding: 3%;
    text-align: left;
    border-radius:0.3rem ;
    -webkit-border-radius:0.3rem ;
    -moz-border-radius:0.3rem ;
    -ms-border-radius:0.3rem ;
    -o-border-radius:0.3rem ;
    p{
      margin-bottom: 0.5%;
    }
}
  
}
