@import "../../../vendors/font.css";
.policybody {
  background-color: #ffffff;
  padding: 0 5% 5%;
}
.policybody .table-content li {
  margin-bottom: 1%;
}
.policybody .table-content li a {
  color: black;
  cursor: pointer;
  font-weight: 600;
}
.policybody h5 {
  font-weight: 700;
}
.policybody h6 {
  font-size: 14px;
  font-weight: 600;
}
.policybody .table, .policybody th, .policybody td {
  border: 1px solid black;
  border-collapse: collapse;
}
.policybody .table td, .policybody .table th, .policybody th td, .policybody th th, .policybody td td, .policybody td th {
  padding: 1%;
}
.policybody .sections {
  border: 1px solid black;
  padding: 3%;
  text-align: left;
  border-radius: 0.3rem;
  -webkit-border-radius: 0.3rem;
  -moz-border-radius: 0.3rem;
  -ms-border-radius: 0.3rem;
  -o-border-radius: 0.3rem;
}
.policybody .sections p {
  margin-bottom: 0.5%;
}


