.container{
    position: relative;
    display: flex;
    justify-content: center;
}
.slide1{
    position: absolute;
    box-shadow: 15px 15px 30px #e9e9e9;
    opacity: 1;
    background-color: white;
    transition: translate 0.5s, z-index 0.5s, opacity 0.5s, box-shadow 0.5s;
    z-index: 1;
}
.slide2{
    position: absolute;
    box-shadow: none;
    transition: translate 0.5s, z-index 0.5s, opacity 0.5s, box-shadow 0.5s;
    translate: 50px 100px;
    background-color: white;
    opacity: 0.9;
    z-index: 0;
}