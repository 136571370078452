@import "../../../vendors/variables";

.footer{
    background-color: white;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    padding:4% 0;
  

    .primary-text{
      color: $primary;
    }
  
    .footer-heading1
  {
      color: #636363;
      font-size: 24px;
    }
  
    .footer-heading2
    {
        color: $primary;
        font-size: 2rem;
      }
  
      .heading-small{
        font-size: 27px;
      }
  
    .sub-heading{
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 3%;
    }
  
    a{
      text-decoration: none;
      color: #212429;
      cursor: pointer;
     
      p{
      margin-top: 2%;
      font-weight: 400;
    }
    }

    .footer-icon{
        width: 70%;
    }
  }