.footer {
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
  padding: 4% 0;
}
.footer .primary-text {
  color: #3546AB;
}
.footer .footer-heading1 {
  color: #636363;
  font-size: 24px;
}
.footer .footer-heading2 {
  color: #3546AB;
  font-size: 2rem;
}
.footer .heading-small {
  font-size: 27px;
}
.footer .sub-heading {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 3%;
}
.footer a {
  text-decoration: none;
  color: #212429;
  cursor: pointer;
}
.footer a p {
  margin-top: 2%;
  font-weight: 400;
}
.footer .footer-icon {
  width: 70%;
}

