$primary:#3546AB;
$secondary:#18204E;
$icon-color:#808080;

@mixin btn-hover {
  &:hover{
    background-color: white;
    color: $primary;
    
    transition: 0.4s;
    -webkit-transition: 0.4s;
    -moz-transition: 0.4s;
    -ms-transition: 0.4s;
    -o-transition: 0.4s;
}
}

 @mixin bold-font{
    font-weight: 600 !important;
}

@mixin primary-button{
    background-color:$primary !important;
    border: 1px solid $primary!important;
    
    &:hover{
      background-color: white !important;
      color: $primary !important;
      transition: 0.5s;
      -webkit-transition: 0.5s;
      -moz-transition: 0.5s;
      -ms-transition: 0.5s;
      -o-transition: 0.5s;
    }

}