 @import "../../../vendors/variables";
 
 // Navbar Component
  .useroptions button{
    font-weight: 600;
    
  }

  
  @media screen and (max-width:600px) {
    .logo{
      width: 85%;
    }
    
  }