@import "../../../vendors/font.css";
body {
  font-family: Inter;
}

.restbody {
  background-color: #f3f6fc;
}

a {
  text-decoration: none;
}

.section {
  margin: 10% 0;
}

.donebtn {
  color: white;
  background-color: #3546AB !important;
}

.donebtn:disabled {
  color: #CCCCCC;
  background-color: #E5E5E5 !important;
}

.icon {
  color: #808080;
}

h2 {
  font-weight: 600 !important;
}

.main-content {
  padding-top: 5%;
}

.formcard {
  position: relative;
}

.cardfields {
  margin-top: 2%;
}

.error {
  margin-top: 1.5% !important;
  color: red;
}

.rdrStartEdge, .rdrEndEdge {
  background-color: #3546AB !important;
  color: white !important;
  font-weight: 600;
  padding: 2%;
  border-radius: 0.3rem;
  -webkit-border-radius: 0.3rem;
  -moz-border-radius: 0.3rem;
  -ms-border-radius: 0.3rem;
  -o-border-radius: 0.3rem;
}

.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrDay:not(.rdrDayPassive) .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
  color: black;
}

.rdrDateRangeWrapper, .rdrMonth {
  width: 100%;
}

.flexibledays {
  display: "inline";
  color: #3546AB;
  font-weight: 600;
}

.days-input {
  padding: 6%;
  font-size: 18px;
  border: 1px solid #E5E7EB;
  border-radius: 0.3rem;
  color: black;
}

.days-input::placeholder {
  color: #999999;
}

.small-text {
  color: #2D2640;
  margin-left: 4%;
}

.selectedDestination {
  width: 100%;
  font-weight: 600;
  color: white;
  display: flex;
  justify-content: space-evenly;
  background: #3546AB;
  padding: 8px;
  border-radius: 1rem;
}

.removeButton {
  font-size: 16px;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
}

.removeButton:hover {
  background-color: rgba(255, 255, 255, 0.3490196078);
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}

.minus, .plus {
  margin: 0 18%;
  border-color: transparent;
  border-radius: 0.2rem;
  -webkit-border-radius: 0.2rem;
  -moz-border-radius: 0.2rem;
  -ms-border-radius: 0.2rem;
  -o-border-radius: 0.2rem;
  font-size: 22px;
  cursor: pointer;
}

.minus {
  padding: 0 5.5%;
  background-color: #F3F6FC;
}

.plus {
  padding: 0 4%;
  background-color: #D8DCF3;
}

.submit-btn {
  background-color: #3546AB !important;
  border: 1px solid #3546AB !important;
}
.submit-btn:hover {
  background-color: white !important;
  color: #3546AB !important;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
}
.submit-btn:disabled {
  background-color: rgb(219, 219, 219) !important;
  border-color: transparent !important;
  color: rgb(165, 165, 165);
}

.existingLink {
  font-size: medium;
  margin-top: 2% !important;
  cursor: pointer;
  text-decoration: underline;
}

.experience {
  padding: 0 3%;
  padding-top: 8%;
}
.experience p {
  font-size: 17px;
}

.sideimg {
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.popular {
  padding: 0 2.8%;
}
.popular .seemore {
  color: #3546AB;
  text-align: right;
}
.popular .popularcards {
  height: 100%;
  width: 100%;
  min-height: 15rem;
}
.popular .popularcards .cardinfo {
  position: relative;
  top: 70%;
  color: white;
  left: 2%;
}
.popular .popularcards .cardinfo h4 {
  font-size: 26px;
}
.popular .popularimg {
  width: 100%;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

@media screen and (max-width: 600px) {
  .logo {
    width: 85%;
  }
  .header-heading {
    font-size: 2.5rem;
  }
  .header-para {
    font-size: 1rem;
  }
  .sideimg {
    width: 100%;
  }
  .popular .cardinfo h4 {
    font-size: 30px;
  }
  .popular .cardinfo p {
    font-size: 14px;
  }
}


