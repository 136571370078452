@import "../../../vendors/variables";

.menu-items{
    padding: 2%;

    .sub-heading{
        font-size: 13px;
        color: gray;
    }
    .item{
        width: 100%;
        background-color: transparent !important;
        color: #535763 !important;
        text-align: left;
        display: flex;
        font-family: inter;
        justify-content: flex-start;
        align-items: flex-start;
        font-weight: 700;
        font-size: 14px;
        padding: 2.7%;
        margin-top: 1.5%;
        &:hover,&:active{
        color: white !important;
background-color: $primary !important;
        }

        svg{
            font-size: 22px;
        }
    }

    .MuiTextField-root{
        width: 50%;
        .MuiOutlinedInput-root{
            padding-left: 2.3%;
        }
        .MuiSelect-select{
            color:#535763 !important ;
            font-weight: 700;
        }
    }
}

