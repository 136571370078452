.useroptions button {
  border: 1px solid white;
  font-weight: 600;
}
.useroptions button:hover {
  background-color: white;
  color: #3546AB;
}

@media screen and (max-width: 600px) {
  .logo {
    width: 85%;
  }
}/*# sourceMappingURL=Navbar.css.map */