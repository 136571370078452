.text-readability {

    font-family: 'Inter', sans-serif;
    line-height: 1.6rem !important;
    /* Increase line height for better spacing between lines */
    color: #333333 !important;
    /* Dark gray for comfortable reading instead of pure black */
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    /* Smooth font rendering */
    letter-spacing: 0.015em !important;
    /* Slight letter spacing for better legibility */
}