.menu-items {
  padding: 2%;
}
.menu-items .sub-heading {
  font-size: 13px;
  color: gray;
}
.menu-items .item {
  width: 100%;
  background-color: transparent !important;
  color: #535763 !important;
  text-align: left;
  display: flex;
  font-family: inter;
  justify-content: flex-start;
  align-items: flex-start;
  font-weight: 700;
  font-size: 14px;
  padding: 2.7%;
  margin-top: 1.5%;
}
.menu-items .item:hover, .menu-items .item:active {
  color: white !important;
  background-color: #3546AB !important;
}
.menu-items .item svg {
  font-size: 22px;
}
.menu-items .MuiTextField-root {
  width: 50%;
}
.menu-items .MuiTextField-root .MuiOutlinedInput-root {
  padding-left: 2.3%;
}
.menu-items .MuiTextField-root .MuiSelect-select {
  color: #535763 !important;
  font-weight: 700;
}


